@font-face {
  font-family: Gilroy;
  src: url("./assets/fonts/Gilroy-Regular.ttf");
}
@font-face {
  font-family: GilroyMed;
  src: url("./assets/fonts/Gilroy-Medium.ttf");
}

* {
  font-family: "Gilroy";
  font-weight: 500;
  background: #ffffff;
}

html {
  height: 100%;
  padding: 0;
  margin: 0;
}
body {
  height: 100%;
  padding: 0;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #f1f1f1 !important;
}

.custom_time_picker {
  background-color: #f5f5f5;
  height: 45px;
  border-radius: 16px;
  margin-top: 6px;
  width: 100%;
}

.ant-picker-ok button span {
  background: transparent !important;
}

.ant-picker-input {
  background-color: #f5f5f5 !important;
}

/* ============================== */

/* Custom prefixed classes */
.sfasfS_switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.sfasfS_switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.sfasfS_slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.sfasfS_slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.sfasfS_switch input:checked + .sfasfS_slider {
  background-color: #2196f3;
}

.sfasfS_switch input:focus + .sfasfS_slider {
  box-shadow: 0 0 1px #2196f3;
}

.sfasfS_switch input:checked + .sfasfS_slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.sfasfS_slider.round {
  border-radius: 34px;
}

.sfasfS_slider.round:before {
  border-radius: 50%;
}

.loader_KfnSdkCmel {
  width: 48px;
  height: 48px;
  border: 5px solid #f7ad00;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation_KfnSdkCmel 1s linear infinite;
}

@keyframes rotation_KfnSdkCmel {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
