.form-input {
  display: flex;
  width: calc(100% - 30px);
  padding: 10px 15px;
  margin-top: 10px;
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 21px;
  background: rgba(252, 252, 252, 1);
}
.form-input > img {
  width: 20px;
  height: 20px;
  margin: auto 0;
  padding: 5px 15px 5px 0;
  border-right: 2px solid rgba(217, 217, 217, 1);
}

.form-input-field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 15px);
  padding-left: 15px;
  background-color: transparent;
  margin: auto 0;
}

.form-input-field > label {
  font-size: 0.625rem;
  color: rgba(130, 130, 130, 1);
  background-color: transparent;
}

.form-input-field > input {
  padding: 0;
  font-size: 1rem;
  font-style: normal;
  outline-style: none;
  border: transparent;
  background-color: transparent;
}

.form-button {
  display: flex;
  width: fit-content;
  justify-content: center;
  cursor: pointer;
}
.form-button:hover {
  opacity: 0.5;
}

.disabled {
  opacity: 0.5;
}

.form-button > button {
  display: block;
  border: transparent;
  background-color: transparent;
  color: white;
  cursor: pointer;
}

.form-button > img {
  background: transparent;
}

.loginBtn {
  width: 30%;
  background-color: rgba(65, 65, 65, 1);
  padding: 10px 20px;
  border-radius: 20px;
  margin: auto;
}

.conferenceBtn {
  background-color: #ffc727;
  padding: 14px 15px;
  white-space: nowrap;
  height: fit-content;
  margin-left: 30px;
}

.modalBtn {
  font-family: "GilroyMed";
  font-size: 1rem;
  background-color: #ffc727;
  padding: 10px 18px;
  white-space: nowrap;
  height: fit-content;
  margin: 30px auto 15px auto;
  border-radius: 10px;
}

.exportBtn {
  font-size: 1rem;
  white-space: nowrap;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 11px;
  background-color: #424242;
  height: fit-content;
  margin: auto 0 auto 30px;
}

.pushNotification {
  font-size: 1rem;
  white-space: nowrap;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 11px;
  background-color: #424242;
  height: fit-content;
  margin: auto 0 auto 30px;
}

.pushNotification img {
  width: 22px;
  height: 22px;
}

.settingBtn {
  width: 150px;
  font-family: "GilroyMed";
  font-size: 1rem;
  background-color: #ffc727;
  padding: 10px 18px;
  white-space: nowrap;
  height: fit-content;
  margin: 30px 0 15px 0;
  border-radius: 18px;
  color: #000000 !important;
}

.graphBtn {
  width: 100px;
  font-family: "GilroyMed";
  font-size: 1rem;
  padding: 10px 18px;
  white-space: nowrap;
  height: fit-content;
  margin: 30px 0 15px 0;
  border-radius: 18px;
  background-color: #ffc727;
}
.graphBtn > button {
  color: #000000;
}
.graphBtn-offline {
  background-color: #f4f5f9;
}

/**file input*/
.file-input {
  position: relative;
  padding: 10px 20px;
  border-radius: 11px;
  background-color: #424242;
  margin: auto 0 auto 30px;
  height: fit-content;
}

.file-input > input {
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 0;
}

.file-input > .label {
  background: transparent;
  display: flex;
}

.file-input > .label > label {
  background: transparent;
  white-space: nowrap;
  font-size: 0.9rem;
  font-weight: 500;
  color: #ffffff;
  margin: auto 5px auto 0;
}

.file-input > .label > img {
  background: transparent;
}

/*select style*/
.modal-form-input {
  width: 150px;
  margin: auto 0;
}

.modal-form-input > .label > img {
  width: 12px;
  margin: auto 8px auto 0;
}
.modal-form-input > .label > label {
  font-size: 0.9rem;
}

.modal-form-input > .main-style {
  text-align: center;
  box-sizing: border-box;
  display: block;
  width: 100% !important;
  height: 35px;
  padding: 15px 20px;
  margin: 7px 0 0 0;
  font-size: 15px;
  border: none;
  border-radius: 2px;
  font-family: "Poppins";
  background: #f4f5f9;
}
.modal-form-input > .select-style {
  display: flex;
  position: relative;
  height: 100%;
}
.modal-form-input > .select-style > p {
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0;
  background: transparent;
  cursor: pointer;
}

.modal-form-input > .select-style > p > span {
  font-family: "Poppins";
  font-size: 15px;
  color: #000000;
  background: transparent;
}

.modal-form-input > .select-style > .option-list {
  list-style: none;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  height: 0px;
  background: #ffffff;
  overflow-y: auto;
  transition: all 0.3s ease-in;
}
.modal-form-input > .select-style > .option-list > li {
  display: block;
  font-size: 0.8rem;
  width: 90%;
  padding: 5%;
  white-space: nowrap;
  cursor: pointer;
  font-family: "Poppins";
  font-size: 15px;
  color: #000000;
}

.modal-form-input > .select-style > .active {
  height: 100px;
  box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.08);
}
